import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
	Box,
	Typography,
	useTheme,
	Button,
	CircularProgress,
} from '@mui/material';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
	Layout,
	OtpForm,
	Seo,
	InstantAirtimeConvertInstructions,
	TwoColGrid,
	BackButton,
} from 'components';
import { requestAirtimeConvertOtp, verifyAirtimeConvertOtp } from 'api';
import { useAlert, useHandleError, useModalAlert } from 'hooks';
import { LINKS, SECOUNDARY_COLOR } from 'utilities';

const ConfirmOtp = () => {
	const counterRef = useRef<any>(null);
	const theme = useTheme();
	const styles = useStyles(theme);
	const alert = useAlert();
	const handleError = useHandleError();
	const modal = useModalAlert();
	const navigate = useNavigate();

	const [isRequestingOtp, setRequestingOtp] = useState<boolean>(false);
	const [isDisabledResentBtn, setDisabledResendBtn] = useState<boolean>(false);
	const [timer, setTimer] = useState<number>(30);

	const { search, state } = useLocation();
	const parseQuery = queryString.parse(search);

	const phone = parseQuery.phone || state?.phone;
	const network = parseQuery.network || state?.network;
	const rate = parseQuery.rate || state?.rate;

	const resetTimer = () => {
		clearInterval(counterRef.current);
		setDisabledResendBtn(false);
		setTimer(30);
		counterRef.current = null;
	};

	useEffect(() => {
		if (timer === 0) {
			resetTimer();
		}
	}, [timer]);

	const startTimer = useCallback(() => {
		counterRef.current = setInterval(() => {
			setTimer((timer) => timer - 1);
		}, 1000);
	}, []);

	const { mutate, isLoading } = useMutation(verifyAirtimeConvertOtp, {
		onSettled: (data, error) => {
			if (data && data.success) {
				const sessionId = data.payload.data.sessionId;
				const airtimeBalance = data.payload.data.airtimeBalance;
				console.log('DATA::', data);
				alert({
					message: 'OTP verified successfully',
					type: 'success',
				});
				navigate(
					`${LINKS.InstantConvertAirtime}?phone=${phone}&network=${network}&sessionId=${sessionId}&airtimeBalance=${airtimeBalance}&rate=${rate}`,
					{
						state: { network, phone, sessionId, airtimeBalance, rate },
					}
				);
			}

			if (error) {
				const response = handleError({ error });
				if (response?.message)
					alert({ message: response.message, type: 'error' });
			}
		},
	});

	const handleConfirm = (otp: string) => {
		if (!network && !phone) {
			modal({
				title: 'Confirm Otp',
				message: 'Something went wrong',
				type: 'error',
				primaryButtonText: 'Close',
				onClickPrimaryButton: () => modal(null),
			});
			return;
		}
		mutate({
			phone,
			network,
			otp,
		});
	};

	const handleRequestOtp = async () => {
		setRequestingOtp(true);
		try {
			const response = await requestAirtimeConvertOtp({
				phone,
				network,
			});

			if (response && response.success) {
				setRequestingOtp(false);
				setDisabledResendBtn(true);
				startTimer();
			}
		} catch (error) {
			setRequestingOtp(false);

			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
	};

	return (
		<Layout>
			<Seo title={'Convert Airtime - Confirm Instant Airtime Convert OTP'} />
			<TwoColGrid rightAside={<InstantAirtimeConvertInstructions isSticky />}>
				<BackButton
					name={'Instant Airtime Convert'}
					sx={{ marginBottom: ['2rem'] }}
				/>
				<Box sx={{ maxWidth: '520px' }}>
					<Typography style={styles.title} variant={'h5'}>
						Instant Airtime to Cash
					</Typography>
					<OtpForm
						isLoading={isLoading}
						callback={(otp: string) => handleConfirm(otp)}
						isInputSecure={false}
					/>
					<Box
						sx={{
							marginTop: '15px',
							display: 'flex',
							gap: '6px',
							alignItems: 'center',
							'& button': {
								color: SECOUNDARY_COLOR,
								cursor: 'pointer',
								fontWeight: 'bold',
							},
						}}
					>
						<span>Didn't get OTP? </span>
						<Button onClick={handleRequestOtp}>Resent OTP</Button>
						{isRequestingOtp ? (
							<CircularProgress size={18} />
						) : (
							isDisabledResentBtn && <span>{timer}</span>
						)}
					</Box>
				</Box>
			</TwoColGrid>
		</Layout>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '300',
		marginBottom: '20px',
	},
});

export default ConfirmOtp;
