import React, { ReactNode, useState } from 'react';
import {
	Box,
	Typography,
	useTheme,
	ListItem as MuiListItem,
} from '@mui/material';
import ManageLimitItem from './manage-limit-item';
import { grey } from '@mui/material/colors';
import { useQuery } from 'react-query';
import CardIcon from '../icons/card';
import VerifiedIcon from '../icons/verified';
import InformationIcon from '../icons/info';
import { useAppSelector } from 'store/hooks';
import { THEME_MODE, KYC_LEVEL, QUERY_KEYS } from 'utilities';
import { useHandleError, useAlert } from 'hooks';
import { kycLimits as kycLimitApi, checkVerificationState } from 'api';

type ListItemProps = {
	icon?: ReactNode;
	children: ReactNode;
};

const ListItem = ({ icon, children }: ListItemProps) => (
	<MuiListItem sx={{ alignItems: 'center', gap: '6px' }} disablePadding>
		{icon}
		<Box sx={{ fontSize: '13px' }} component={'span'}>
			{children}
		</Box>
	</MuiListItem>
);

const ManageLimits = () => {
	const theme = useTheme();
	const alert = useAlert();
	const handleError = useHandleError();
	const styles = useStyles(theme);
	const [kycLimits, setKycLimits] = useState<{ [key: string]: any } | null>(
		null
	);
	const {
		theme: { mode },
		auth: { user, token },
	} = useAppSelector((store) => store);

	const [levelThreeState, setLevelThreeState] = useState<boolean>(false);

	const iconColor =
		mode === THEME_MODE.dark ? grey[50] : theme.palette.primary.main;

	useQuery(
		QUERY_KEYS.VerificationState,
		() =>
			checkVerificationState({
				level: 3,
			}),
		{
			enabled: !!token,
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}

				if (data && data.success) {
					const payload = data.payload[0];
					if (payload && payload.status === 'PENDING') {
						setLevelThreeState(true);
					}
				}
			},
		}
	);

	useQuery(QUERY_KEYS.KycLimits, kycLimitApi, {
		enabled: !!token,
		refetchOnWindowFocus: false,
		onSettled: (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res?.message) {
					alert({ message: res.message, type: 'error' });
				}
			}
			if (data && data.success) {
				const payload = data.payload;
				let store_key = {};
				for (let i in payload) {
					store_key = { ...store_key, [payload[i].level]: payload[i] };
				}
				setKycLimits(store_key);
			}
		},
	});

	return (
		<Box>
			<Typography style={styles.title} variant={'h5'}>
				Manage Limits
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: {
						xs: '1fr',
						sm: 'repeat(2, 1fr)',
						md: 'repeat(3, 1fr)',
					},
					gap: { xs: '3rem', md: '6rem' },
				}}
			>
				<ManageLimitItem
					isActive={user && user.kycLevel >= KYC_LEVEL.LevelOne ? true : false}
					disabled={user && user?.kycLevel >= KYC_LEVEL.LevelOne ? true : false}
					level={KYC_LEVEL.LevelOne}
					data={kycLimits && kycLimits[1]}
					btnText={
						user && user.kycLevel >= KYC_LEVEL.LevelOne
							? 'Completed'
							: 'Upgrade'
					}
				>
					<ListItem icon={<InformationIcon color={iconColor} />}>
						Your Personal Information
					</ListItem>
				</ManageLimitItem>
				<ManageLimitItem
					isActive={user && user.kycLevel >= KYC_LEVEL.LevelTwo ? true : false}
					disabled={user && user?.kycLevel >= KYC_LEVEL.LevelTwo ? true : false}
					level={KYC_LEVEL.LevelTwo}
					data={kycLimits && kycLimits[2]}
					btnText={
						user && user.kycLevel >= KYC_LEVEL.LevelTwo
							? 'Completed'
							: 'Upgrade'
					}
				>
					<ListItem icon={<InformationIcon color={iconColor} />}>
						Your Personal Information
					</ListItem>

					<ListItem icon={<CardIcon color={iconColor} />}>
						Identify verification (NIN)
					</ListItem>
				</ManageLimitItem>
				<ManageLimitItem
					isActive={
						user && user.kycLevel === KYC_LEVEL.levelThree ? true : false
					}
					disabled={
						user && user?.kycLevel === KYC_LEVEL.levelThree
							? true
							: levelThreeState
					}
					level={KYC_LEVEL.levelThree}
					data={kycLimits && kycLimits[3]}
					btnText={
						user && user.kycLevel === KYC_LEVEL.levelThree
							? 'Completed'
							: levelThreeState
							? 'Pending'
							: 'Upgrade'
					}
				>
					<ListItem icon={<InformationIcon color={iconColor} />}>
						Your Personal Information
					</ListItem>
					<ListItem icon={<CardIcon color={iconColor} />}>
						Identify verification (NIN)
					</ListItem>
					<ListItem icon={<VerifiedIcon color={iconColor} />}>
						Bank Verification Number needed
					</ListItem>
				</ManageLimitItem>
			</Box>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(4),
	},
});

export default ManageLimits;
