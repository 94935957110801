import React, { useState } from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import TextInput from '../form-components/text-input';
import Button from '../button';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import Select from '../form-components/select';
import { QUERY_KEYS, INetwork, PHONE_REX, LINKS } from 'utilities';
import { useHandleError, useAlert, useCheckKycLevelTwo } from 'hooks';
import { autoAirtimeConvertNetworks, requestAirtimeConvertOtp } from 'api';

const SELECT_NETWORK = 'Select network provider';

interface IInitialValues {
	phone: string;
	network: string;
}

const AirtimeConvertOtpForm = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const alert = useAlert();
	const checkKycLevelTwo = useCheckKycLevelTwo();

	const [isRequestingOtp, setRequestingOtp] = useState<boolean>(false);
	const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);

	const initialValues: IInitialValues = {
		phone: '',
		network: SELECT_NETWORK,
	};

	const validationSchema = yup.object().shape({
		network: yup
			.string()
			.notOneOf([SELECT_NETWORK], 'Select a network')
			.required('Select network'),
		phone: yup
			.string()
			.required('Enter phone number')
			.matches(PHONE_REX, 'Phone number must be number'),
	});

	const { isLoading: isLoadingNetwork, data: dataAirtimeNetworks } = useQuery(
		[QUERY_KEYS.AutoAirtimeConvertNetwork],
		() => autoAirtimeConvertNetworks({ sort: '-createdAt', isActive: true }),
		{
			refetchOnWindowFocus: false,
		}
	);

	const handleRequestOtp = async (data: IInitialValues) => {
		setRequestingOtp(true);
		try {
			const response = await requestAirtimeConvertOtp(data);

			if (response && response.success) {
				setRequestingOtp(false);
				resetForm();
				navigate(
					`${LINKS.ConfirmConvertAirtimeOtp}?phone=${data.phone}&network=${data.network}&rate=${selectedNetwork?.rate}`,
					{
						state: {
							network: data.network,
							phone: data.phone,
							rate: selectedNetwork?.rate,
						},
					}
				);
			}
		} catch (error) {
			setRequestingOtp(false);

			const response = handleError({ error });
			if (response?.message)
				alert({ message: response.message, type: 'error' });
		}
	};

	const {
		handleSubmit,
		handleChange,
		errors,
		touched,
		values,
		resetForm,
		setFieldValue,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			handleRequestOtp(values);
		},
	});

	const { phone, network } = values;

	const handleSelectNetwork = (id: string) => {
		setFieldValue('network', id);
		const network =
			dataAirtimeNetworks &&
			dataAirtimeNetworks?.payload.find((network) => network.id === id);
		if (network) setSelectedNetwork(network);
	};

	return (
		<>
			<Box style={styles.form as any} component={'form'}>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Network
					</Typography>
					<Select
						fullWidth
						error={touched.network && Boolean(errors.network)}
						helpertext={touched.network && errors.network}
						value={network}
						onChange={(e) => {
							const value = e.target.value as string;
							handleSelectNetwork(value);
						}}
					>
						<MenuItem disabled value={SELECT_NETWORK}>
							{isLoadingNetwork ? 'Loading...' : SELECT_NETWORK}
						</MenuItem>
						{dataAirtimeNetworks && dataAirtimeNetworks.payload.length > 0 ? (
							dataAirtimeNetworks.payload.map((network: INetwork) => (
								<MenuItem value={network.id} key={network.id}>
									{network.name}
								</MenuItem>
							))
						) : (
							<MenuItem>No avialable network</MenuItem>
						)}
					</Select>
				</Box>
				<Box>
					<Typography
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Phone number
					</Typography>
					<TextInput
						error={touched.phone && Boolean(errors.phone)}
						helperText={touched.phone && errors.phone}
						fullWidth
						placeholder={'Type in phone number here'}
						value={phone}
						onChange={handleChange('phone')}
					/>
				</Box>

				<Button
					loading={isRequestingOtp}
					style={styles.btn}
					size={'large'}
					onClick={(e: React.FormEvent<HTMLButtonElement>) => {
						e.preventDefault();
						if (checkKycLevelTwo()) handleSubmit();
					}}
				>
					Proceed
				</Button>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
});

export default AirtimeConvertOtpForm;
