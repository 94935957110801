import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	useTheme,
	InputAdornment,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import TextInput from '../form-components/text-input';
import Button from '../button';
import Select from '../form-components/select';
import { airtimeNetworks, purchaseAirtime } from 'api';
import {
	INetwork,
	PHONE_REX,
	calculateDiscountedAmount,
	QUERY_KEYS,
	BENEFICIARY_TYPE,
	TRANSACTION_SERVICE,
} from 'utilities';
import { setTransactionBreakdown } from 'store/transaction';
import { useAppDispatch } from 'store/hooks';
import {
	useModalAlert,
	useHandleError,
	useVerifyPin,
	useVerifyCoupon,
	useAlert,
} from 'hooks';
import SelectBeneficiary from 'components/beneficiary/select-beneficiary';
import CheckBox from '../form-components/check-box';
import { useCreateBeneficiary } from 'hooks';

const SELECT_PROVIDER = 'Select network provider';

const PurchaseAirtimeForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const [beneficiary, setBeneficiary] = useState<null | { [key: string]: any }>(
		null
	);

	const [isSaveBeneficiary, setSaveBeneficiary] = useState<boolean>(false);

	const { createBeneficiary } = useCreateBeneficiary(() => {
		setBeneficiary(null);
	});

	const { coupon, clearCoupon, verifyCoupon, isVerifyingCoupon } =
		useVerifyCoupon();

	const [selectedNetwork, setSelectedNetwork] = React.useState<null | INetwork>(
		null
	);

	useEffect(
		() => {
			if (coupon) {
				dispatch(
					setTransactionBreakdown({
						coupon,
					})
				);
			}
		},
		// eslint-disable-next-line
		[coupon]
	);

	const validationSchema = yup.object().shape({
		phone_number: yup
			.string()
			.matches(PHONE_REX, 'Invalid phone number')
			.required('Phone number is required'),
		network: yup
			.string()
			.notOneOf([SELECT_PROVIDER], SELECT_PROVIDER)
			.required('Network provider is required'),
		amount: yup
			.number()
			.positive('Amount cannot be negative')
			.required('Amount is required'),
	});

	const initialValues = {
		phone_number: '',
		network: SELECT_PROVIDER,
		amount: '',
		discount_code: '',
	};

	const { isLoading: isPurchasingAirtime, mutate: mutatePurchaseAirtime } =
		useMutation(purchaseAirtime, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							title: 'Airtime Purchase',
							message: response.message,
							hasCloseButton: true,
							type: 'error',
							primaryButtonText: 'Close',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}
				if (data && data.success) {
					if (isSaveBeneficiary) {
						createBeneficiary({
							type: BENEFICIARY_TYPE.DATA,
							record: beneficiary,
						});
					}
					queryClient.invalidateQueries([QUERY_KEYS.UserWallet]);
					queryClient.invalidateQueries([QUERY_KEYS.Transactions]);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					dispatch(setTransactionBreakdown(null));
					setSelectedNetwork(null);
					resetForm();
					modal({
						title: 'Airtime Purchase',
						message: `Airtime purchase is successful!`,
						type: 'success',
						hasCloseButton: true,
						primaryButtonText: 'Purchase again',
						secondaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
						onClickSecondaryButton: () => modal(null),
					});
				}
			},
		});

	const {
		errors,
		values,
		touched,
		handleChange,
		handleSubmit,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			const data = {
				amount: values.amount,
				phone_number: values.phone_number,
				network: values.network,
			} as typeof initialValues;

			let amount = Number(values.amount);

			if (values.discount_code) {
				if (!coupon) {
					return alert({ message: 'Apply discount code', type: 'info' });
				}

				if (coupon && coupon.name !== values.discount_code) {
					return alert({ message: 'Invalid coupon code', type: 'error' });
				}

				data.discount_code = values.discount_code;
				amount = calculateDiscountedAmount({ coupon, amount });
			}

			// mutatePurchaseAirtime(data);

			if (isSaveBeneficiary) {
				setBeneficiary({
					number: values.phone_number,
					transactionType: TRANSACTION_SERVICE.AIRTIME_TOP_UP,
					provider: selectedNetwork,
				});
			} else {
				setBeneficiary(null);
			}

			const callback = () => {
				verifyPin(null);
				mutatePurchaseAirtime(data);
			};
			verifyPin({
				title: 'Airtime Purchase',
				message: `Verify transaction pin to confirm the purchase of ${amount} worth of ${selectedNetwork?.name} Airtime`,
				callback,
			});

			// if (checkKycLevel()) {
			// }
		},
	});

	const { phone_number, amount, network, discount_code } = values;

	// Airtime Nertwork
	const { isLoading: isLoadingAirtimeNetworks, data: dataAirtimeNetworks } =
		useQuery(
			'airtimeNetworks',
			() =>
				airtimeNetworks({
					isActive: true,
				}),
			{
				refetchOnWindowFocus: false,
			}
		);

	const handleSelectProvider = (provider: string) => {
		setFieldValue('network', provider);
		if (dataAirtimeNetworks) {
			const network = dataAirtimeNetworks.payload.find(
				(network: INetwork) => network.id === provider
			);
			if (network) setSelectedNetwork(network);
		}
	};

	const onChangeCode = (code: string) => {
		clearCoupon();
		setFieldValue('discount_code', code);
	};

	const handleApplyCode = () => {
		if (discount_code) {
			verifyCoupon(discount_code);
		}

		alert({ message: 'Enter discount code', type: 'info' });
	};

	const handleSelectBeneficiary = (beneficiary: { [key: string]: any }) => {
		const phone = beneficiary.record.phone_number;
		const network = beneficiary.record.network;

		setFieldValue('network', network.id);
		setFieldValue('phone_number', phone);
		setSelectedNetwork(network);
	};

	return (
		<Box style={styles.form as any} component={'form'}>
			<Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					Network provider
				</Typography>
				<Select
					fullWidth
					error={touched.network && Boolean(errors.network)}
					helpertext={touched.network && errors.network}
					value={network}
					onChange={(e) => handleSelectProvider(e.target.value as string)}
				>
					<MenuItem disabled value={SELECT_PROVIDER}>
						{isLoadingAirtimeNetworks
							? 'Loading...'
							: dataAirtimeNetworks && dataAirtimeNetworks.payload.length === 0
							? 'No available airtime network'
							: SELECT_PROVIDER}
					</MenuItem>
					{dataAirtimeNetworks &&
						dataAirtimeNetworks.payload.map((network: INetwork) => (
							<MenuItem key={network.id} value={network.id}>
								{network.name}
							</MenuItem>
						))}
				</Select>
			</Box>
			<Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '8px',
					}}
				>
					<Typography
						sx={{
							marginBottom: '0px !important',
						}}
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Phone number
					</Typography>
					<SelectBeneficiary
						onSelect={handleSelectBeneficiary}
						type={BENEFICIARY_TYPE.AIRTIME}
					/>
				</Box>
				<TextInput
					fullWidth
					placeholder={'Type in phone number here'}
					error={touched.phone_number && Boolean(errors.phone_number)}
					helperText={touched.phone_number && errors.phone_number}
					value={phone_number}
					onChange={handleChange('phone_number')}
				/>
			</Box>
			<Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					Amount
				</Typography>
				<TextInput
					fullWidth
					placeholder={'Type in amount'}
					error={touched.amount && Boolean(errors.amount)}
					helperText={touched.amount && errors.amount}
					value={amount}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						const amount = e.target.value;
						setFieldValue('amount', amount);
						dispatch(
							setTransactionBreakdown({
								serviceCost: Number(amount),
							})
						);
					}}
				/>
			</Box>

			<Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					Discount code [optional]
				</Typography>
				<TextInput
					disabled={isVerifyingCoupon}
					value={discount_code}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangeCode(e.target.value)
					}
					fullWidth
					placeholder={'Type in discount code here'}
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<Button
									loading={isVerifyingCoupon}
									onClick={handleApplyCode}
									disableRipple
									style={styles.applyBtn}
								>
									Apply
								</Button>
							</InputAdornment>
						),
					}}
				/>
			</Box>
			<Box>
				<CheckBox
					onChange={() => setSaveBeneficiary(!isSaveBeneficiary)}
					value={isSaveBeneficiary}
					label={'Save number for later'}
				/>
			</Box>
			<Button
				loading={isPurchasingAirtime}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					handleSubmit();
				}}
				size={'large'}
				style={styles.btn}
			>
				Purchase
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default PurchaseAirtimeForm;
