import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import WalletOperationItem from './wallet-opertaion-item';
import { THEME_MODE, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';

const OPERATIONS = [
	{
		title: 'Fund wallet',
		description: `Add money to your AirtimeFlip wallet from your credit card or bank account.
			`,
		img: require('assets/icons/fund-wallet.png'),
		link: LINKS.WalletTopup,
	},
	{
		title: 'Withdraw fund',
		description:
			'Send money to your bank account from your airtimeflip wallet.',
		img: require('assets/icons/withdraw.png'),
		link: LINKS.WalletWithdraw,
	},
	{
		title: 'Transfer fund',
		description:
			'Transfer funds from your airtimeflip wallet to other airtimeflip users.',
		img: require('assets/icons/transfer.png'),
		link: LINKS.WalletTransfer,
	},
];

const WalletOpertaions = () => {
	const theme = useTheme();
	const themeMode = useAppSelector((store) => store.theme.mode);
	const styles = useStyles(theme, themeMode);

	return (
		<Box>
			<Typography style={styles.title} variant={'h5'}>
				Wallet Operations
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: {
						xs: '1fr',
						sm: 'repeat(2, 1fr)',
						md: 'repeat(3, 1fr)',
					},
					gap: '15px',
				}}
			>
				{OPERATIONS.map((data, key) => (
					<WalletOperationItem data={data} key={key} />
				))}
			</Box>
		</Box>
	);
};

const useStyles = (theme: any, mode?: any) => ({
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
	},
});

export default WalletOpertaions;
