import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';
import Carousel from 'react-multi-carousel';
import { grey } from '@mui/material/colors';
import QuickServiceItem from './quick-service-item';
import { THEME_MODE, LINKS } from 'utilities';
import { useAppSelector } from 'store/hooks';

// Icon
import AirtimeConvertIcon from 'components/icons/airtime-convert';
import PurchaseDataIcon from 'components/icons/purchase-data';
import PurchaseAirtimeIcon from 'components/icons/purchase-airtime';
import PowerBillIcon from 'components/icons/power-bill';
import FundWalletIcon from 'components/icons/fund-wallet';

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const QuickServices = () => {
	const theme = useTheme();
	const appTheme = useAppSelector((store) => store.theme);
	const styles = useStyles(theme, appTheme.mode);
	return (
		<Box sx={{ overflow: 'hidden' }}>
			<Box
				sx={{
					display: 'flex',
					alignItems: { md: 'center' },
					justifyContent: 'space-between',
					gap: theme.spacing(1),
					marginBottom: theme.spacing(3),
				}}
			>
				<Typography style={styles.title} variant={'h5'}>
					Quick Services
				</Typography>
				<NavLink style={styles.link} to={LINKS.services}>
					<Typography sx={{ fontWeight: '600' }}>See all</Typography>
					<ChevronRightRounded />
				</NavLink>
			</Box>
			<Box
				sx={{
					overflow: 'hidden',
					display: 'grid',
					gap: ['15px'],
					gridTemplateColumns: {
						xs: 'repeat(2, 1fr)',
						sm: 'repeat(3, 1fr)',
						md: 'repeat(4, 1fr)',
					},
				}}
			>
				<QuickServiceItem
					icon={AirtimeConvertIcon}
					title={'Convert airtime'}
					description={'Convert your airtime to cash at amazing rates'}
					link={LINKS.ConvertAirtime}
				/>
				<QuickServiceItem
					icon={PurchaseDataIcon}
					title={'Purchase data'}
					description={
						'Make data purchases to any network and pay from your mobile wallet.'
					}
					iconSize={'32px'}
					link={LINKS.PurchaseData}
				/>
				<QuickServiceItem
					icon={PurchaseAirtimeIcon}
					title={'Airtime Purchase'}
					description={`Make airtime purchases to any network and pay from your mobile wallet.
						`}
					iconSize={'18px'}
					link={LINKS.PurchaseAirtime}
				/>
				<QuickServiceItem
					icon={PowerBillIcon}
					title={'Electricity bill payment'}
					description={`Pay your electricity bill online - fast, secure, and hassle-free.`}
					iconSize={'18px'}
					link={`${LINKS.PayBill}/?tab=power-bill`}
				/>

				<QuickServiceItem
					icon={(props: any) => <FundWalletIcon {...props} height={24} />}
					title={'Fund wallet'}
					description={`Add money to your AirtimeFlip wallet from your credit card or bank account.`}
					iconSize={'24px'}
					link={LINKS.WalletTopup}
				/>
				{/* <Carousel ssr={true} deviceType={'desktop'} responsive={responsive}> */}
				{/* </Carousel> */}
			</Box>
		</Box>
	);
};

const useStyles = (theme: any, mode: string) => ({
	title: {
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
	},
	link: {
		textDecoration: 'none',
		fontWeight: '600',
		color: mode === THEME_MODE.dark ? grey[300] : 'inherit',
		display: 'flex',
		gap: theme.spacing(1),
		alignItems: 'center',
	},
});

export default QuickServices;
