import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography, useTheme, InputAdornment } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import TextInput from '../form-components/text-input';
import Button from '../button';
import CustomTextInputHolder from '../partials/custom-text-input-holder';
import { billPayment, billProviders, billProducts } from 'api';
import {
	calculateDiscountedAmount,
	QUERY_KEYS,
	API_ENDPOINTS,
	formatNumberToCurrency,
	IProvider,
	Product,
} from 'utilities';
import { setTransactionBreakdown } from 'store/transaction';
import { useAppDispatch } from 'store/hooks';
import {
	useModalAlert,
	useHandleError,
	useVerifyPin,
	useVerifyCoupon,
	useAlert,
} from 'hooks';
import CustomSelect from 'components/form-components/custom-select';

const SELECT_PROVIDER = 'Select provider';
const SELECT_PRODUCT = 'Select product';

interface IBillerAccount {
	name: string;
	username: string;
	type: string;
	customerId: string;
	accountId: string;
	reference: string;
	accountNumber: any;
	phoneNumber: any;
	emailAddress: any;
	canVend: boolean;
	minPayableAmount: number;
	charge: number;
	customerName: string;
}

const FundBettingAccountForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const [billerAccount, setBillerAccount] = useState<null | IBillerAccount>(
		null
	);

	const [selectedProduct, setSelectedProduct] = useState<null | Product>(null);

	const [isLoadingProducts, setLoadingProducts] = useState<boolean>(false);
	const [bettingProducts, setBettingProducts] = useState<null | Product[]>(
		null
	);

	const [isVerifyBillerAccount, setVerifyBillAccount] =
		useState<boolean>(false);

	const { coupon, clearCoupon, verifyCoupon, isVerifyingCoupon } =
		useVerifyCoupon();

	const isProductPriceRanged = selectedProduct?.priceType === 'range';

	useEffect(
		() => {
			if (coupon) {
				dispatch(
					setTransactionBreakdown({
						coupon,
					})
				);
			}
		},
		// eslint-disable-next-line
		[coupon]
	);

	const validationSchema = yup.object().shape({
		customerId: yup.string().required('Customer ID is required'),
		service_type: yup
			.string()
			.notOneOf([SELECT_PROVIDER], SELECT_PROVIDER)
			.required('Network provider is required'),
		productId: yup
			.string()
			.notOneOf([SELECT_PRODUCT], SELECT_PRODUCT)
			.required('Network product is required'),
		amount: yup
			.number()
			.positive('Amount cannot be negative')
			.required('Amount is required'),
	});

	const initialValues = {
		service_type: SELECT_PROVIDER,
		amount: '',
		discount_code: '',
		customerId: '',
		productId: '',
		operatorId: '',
	};

	const handleResetState = () => {
		resetForm();
		setBillerAccount(null);
		setSelectedProduct(null);
	};

	const {
		isLoading: isFundingBettingAccount,
		mutate: mutateFundBettingAccount,
	} = useMutation(billPayment, {
		onSettled: (data, error) => {
			if (error) {
				const response = handleError({ error });
				if (response?.message) {
					modal({
						title: 'Failed',
						message: response.message,
						hasCloseButton: true,
						type: 'error',
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
					});
				}
			}
			if (data && data.success) {
				queryClient.invalidateQueries([QUERY_KEYS.UserWallet]);
				queryClient.invalidateQueries([QUERY_KEYS.Transactions]);
				queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
				dispatch(setTransactionBreakdown(null));

				const payload = data.payload;

				resetForm();
				modal({
					title: 'Success',
					message: `Betting account ${billerAccount?.name} with customer ID ${payload?.customerId} has been funded successful!`,
					type: 'success',
					hasCloseButton: true,
					primaryButtonText: 'Fund again',
					secondaryButtonText: 'Close',
					onClickPrimaryButton: () => {
						handleResetState();
						modal(null);
					},
					onClickSecondaryButton: () => {
						handleResetState();
						modal(null);
					},
				});
			}
		},
	});

	const {
		errors,
		values,
		touched,
		handleSubmit,
		setFieldValue,
		resetForm,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			const data: { [key: string]: any } = {
				productId: Number(values.productId),
				operatorId: Number(values.operatorId),
				service_type: values.service_type,
				customerId: values.customerId,
				// amount: values.amount,
			};

			let amount = parseFloat(values.amount) || 0;

			const price = selectedProduct?.price;

			const currency = selectedProduct?.currency?.operator;

			if (selectedProduct) {
				if (isProductPriceRanged) {
					const minAmount = parseFloat(`${price?.min.operator}`);
					const maxAmount = parseFloat(`${price?.max.operator}`);

					if (amount < minAmount) {
						setFieldError(
							'amount',
							`Amount must be more than ${formatNumberToCurrency(
								minAmount,
								currency
							)}`
						);
						return;
					}
					if (amount > maxAmount) {
						setFieldError(
							'amount',
							`Amount must be less than ${formatNumberToCurrency(
								maxAmount,
								currency
							)}`
						);
						return;
					}

					data.amount = selectedProduct.conversion_rate
						? selectedProduct.conversion_rate * amount
						: amount;
				} else {
					data.amount = amount;
				}
			}

			if (values.discount_code) {
				if (!coupon) {
					return alert({ message: 'Apply discount code', type: 'info' });
				}

				if (coupon && coupon.name !== values.discount_code) {
					return alert({ message: 'Invalid coupon code', type: 'error' });
				}

				data.discount_code = values.discount_code;
				amount = calculateDiscountedAmount({ coupon, amount });
			}

			const callback = () => {
				verifyPin(null);
				mutateFundBettingAccount({
					data: data as any,
					url: 'bills/betting/funding',
				});
			};
			verifyPin({
				title: 'Bet funding Purchase',
				message: `Verify transaction pin to confirm the Bet funding of ${formatNumberToCurrency(
					amount
				)}`,
				callback,
			});
		},
	});

	const { customerId, amount, discount_code, operatorId, productId } = values;

	// Load Biller

	const {
		isLoading: isLoadingBettingProviders,
		data: dataBettingProviders,
		refetch,
	} = useQuery(
		['betting/billers'],
		() => billProviders(`${API_ENDPOINTS.Bill}/betting/billers`),
		{
			refetchOnWindowFocus: false,
			onSettled: (data, error) => {
				if (error) {
					const res = handleError({ error });
					if (res?.message) {
						alert({ message: res.message, type: 'error' });
					}
				}
			},
		}
	);

	// Verify Customeer ID
	const handleVerifyCustomerId = async () => {
		setVerifyBillAccount(true);
		try {
			const response = await billProviders(
				`${API_ENDPOINTS.Bill}/betting/validate`,
				{
					type: productId,
					customerId,
				}
			);

			if (response && response.success) {
				setBillerAccount(response.payload as any);
			}
			setVerifyBillAccount(false);
		} catch (error) {
			setVerifyBillAccount(false);

			if (error) {
				const res = handleError({ error });
				if (res?.message) {
					alert({ message: res.message, type: 'error' });
				}
			}
		}
	};

	const onChangeCode = (code: string) => {
		clearCoupon();
		setFieldValue('discount_code', code);
	};

	const handleApplyCode = () => {
		if (discount_code) {
			verifyCoupon(discount_code);
		}

		alert({ message: 'Enter discount code', type: 'info' });
	};

	const hasVerifyBillerAccount = Boolean(
		billerAccount && billerAccount.accountId === customerId
	);

	const getBettingProducts = async (providerId: string) => {
		try {
			setLoadingProducts(true);
			const response = await billProducts({
				providerId,
			});
			if (response && response.success) setBettingProducts(response.payload);
		} catch (error) {
			const response = handleError({ error });
			if (response?.message) {
				alert({
					message: response.message,
					type: 'error',
				});
			}
		} finally {
			setLoadingProducts(false);
		}
	};

	// Handlee Select Provider
	const handleSelectProvider = async (operatorId: string) => {
		if (dataBettingProviders && dataBettingProviders.payload) {
			const provider = dataBettingProviders.payload.find(
				(provider: IProvider) => provider.id === operatorId
			);
			if (provider) {
				setFieldValue('operatorId', operatorId, true);
				setFieldValue('service_type', provider?.name);
			}
		}
		setFieldValue('productId', '');
		setFieldValue('amount', '');
		dispatch(
			setTransactionBreakdown({
				serviceCost: 0,
				currency: 'NGN',
			})
		);

		// Retrieve products
		await getBettingProducts(operatorId); // Call products
	};

	const handleSelectProduct = (productId: string): void => {
		// Clear state
		dispatch(setTransactionBreakdown(null));
		setFieldValue('amount', '');

		if (bettingProducts) {
			const foundProduct = bettingProducts.find(
				(product) => product.id === productId
			);

			if (foundProduct) {
				setSelectedProduct(foundProduct);
				setFieldValue('productId', foundProduct.id);
				setFieldValue('product', foundProduct.name);

				if (foundProduct.priceType === 'fixed') {
					const amount = foundProduct.price.user;
					setFieldValue('amount', amount);
					dispatch(
						setTransactionBreakdown({
							serviceCost: parseFloat(amount),
							currency: foundProduct.currency.user,
						})
					);
				}
			}
		}
	};

	const handleChangeCustomerId = (value: string) => {
		setBillerAccount(null);
		setFieldValue('customerId', value);
	};

	return (
		<Box style={styles.form as any} component={'form'}>
			<CustomSelect
				labelText={SELECT_PROVIDER}
				isLoading={isLoadingBettingProviders}
				placeholder={SELECT_PROVIDER}
				error={Boolean(touched.operatorId && errors.operatorId)}
				helpertext={touched.operatorId && errors.operatorId}
				options={
					dataBettingProviders
						? [
								{ label: SELECT_PROVIDER, value: '' },
								...dataBettingProviders.payload.map((data) => ({
									label: data.name,
									value: data.id,
								})),
						  ]
						: [{ label: SELECT_PROVIDER, value: '' }]
				}
				value={operatorId}
				onChange={(value) => handleSelectProvider(value)}
			/>
			<CustomSelect
				labelText={SELECT_PRODUCT}
				isLoading={isLoadingProducts}
				placeholder={SELECT_PRODUCT}
				error={Boolean(touched.productId && errors.productId)}
				helpertext={touched.productId && errors.productId}
				options={
					bettingProducts
						? [
								{ label: SELECT_PRODUCT, value: '' },
								...bettingProducts.map((data) => ({
									label: data.name,
									value: data.id,
								})),
						  ]
						: [{ label: SELECT_PRODUCT, value: '' }]
				}
				value={productId}
				onChange={(value) => handleSelectProduct(value)}
			/>

			<TextInput
				fullWidth
				labelText={'Customer ID'}
				placeholder={'Type in customer ID here'}
				error={touched.customerId && Boolean(errors.customerId)}
				helperText={touched.customerId && errors.customerId}
				value={customerId}
				onChange={(e) => {
					const value = e.target.value;
					handleChangeCustomerId(value);
				}}
			/>

			{hasVerifyBillerAccount && (
				<>
					<Box>
						<Typography
							style={styles.label}
							component={'label'}
							variant={'body1'}
						>
							Account name
						</Typography>
						<CustomTextInputHolder
							text={billerAccount?.customerName as string}
						/>
					</Box>
					{isProductPriceRanged && (
						<Box>
							<TextInput
								labelText={
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Typography
											sx={{
												display: 'inline-block',
												fontWeight: '600',
											}}
										>
											Amount
										</Typography>
										<Typography
											sx={{
												display: ['inline-block', 'inline-block', 'none'],
											}}
										>
											{formatNumberToCurrency(
												parseFloat(amount) * selectedProduct?.conversion_rate
													? parseFloat(`${selectedProduct?.conversion_rate}`)
													: 1,
												selectedProduct?.currency?.user
											)}
										</Typography>
									</Box>
								}
								fullWidth
								placeholder={'Type in amount'}
								error={touched.amount && Boolean(errors.amount)}
								helperText={touched.amount && errors.amount}
								value={amount}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									const amount = e.target.value;
									setFieldValue('amount', amount);

									const serviceCost = selectedProduct?.conversion_rate
										? parseFloat(amount) * selectedProduct?.conversion_rate
										: parseFloat(`${amount}`);

									dispatch(
										setTransactionBreakdown({
											serviceCost,
											currency: selectedProduct?.currency?.user,
										})
									);
								}}
							/>
						</Box>
					)}
					<TextInput
						labelText={'Discount code [optional]'}
						disabled={isVerifyingCoupon}
						value={discount_code}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							onChangeCode(e.target.value)
						}
						fullWidth
						placeholder={'Type in discount code here'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<Button
										loading={isVerifyingCoupon}
										onClick={handleApplyCode}
										disableRipple
										style={styles.applyBtn}
									>
										Apply
									</Button>
								</InputAdornment>
							),
						}}
					/>
				</>
			)}

			<Button
				loading={isVerifyBillerAccount || isFundingBettingAccount}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					if (hasVerifyBillerAccount) {
						handleSubmit();
						return;
					}

					if (!customerId)
						return alert({
							message: 'Enter customer ID',
							type: 'info',
						});

					handleVerifyCustomerId();
				}}
				size={'large'}
				style={styles.btn}
			>
				{hasVerifyBillerAccount ? 'Purchase' : 'Validate Account'}
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default FundBettingAccountForm;
