import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	Box,
	Typography,
	useTheme,
	// InputAdornment,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import TextInput from '../form-components/text-input';
import Button from '../button';
import {
	internationalDataProducts,
	internationalDataPurchase,
	parseMsisdn,
	internationalDataProviders,
} from 'api';
import {
	INetwork,
	QUERY_KEYS,
	BENEFICIARY_TYPE,
	TRANSACTION_SERVICE,
	IProvider,
	Product,
	formatNumberToCurrency,
	IPhoneVerifiedData,
	SOCHITEL_LINKS,
} from 'utilities';
import { setTransactionBreakdown } from 'store/transaction';
import { useAppDispatch } from 'store/hooks';
import {
	useModalAlert,
	useHandleError,
	useVerifyPin,
	useVerifyCoupon,
	useAlert,
} from 'hooks';
import CheckBox from '../form-components/check-box';
import { useCreateBeneficiary } from 'hooks';
import SelectBeneficiary from 'components/beneficiary/select-beneficiary';
import PhoneTextField from 'components/form-components/phone-text-field';
import CustomSelect from 'components/form-components/custom-select';
import CustomSelectWithImage from 'components/form-components/custom-select-with-image';

const SELECT_PROVIDER = 'Select provider';
const SELECT_PACKAGE = 'Select package';

const InternationalDataForm = () => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const handleError = useHandleError();
	const modal = useModalAlert();
	const verifyPin = useVerifyPin();
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const [beneficiary, setBeneficiary] = useState<null | { [key: string]: any }>(
		null
	);

	const [isSaveBeneficiary, setSaveBeneficiary] = useState<boolean>(false);

	const [isVerifyingPhone, setVerifyingPhone] = useState<boolean>(false);
	const [phoneVerifiedData, setPhoneVerifiedData] =
		useState<null | IPhoneVerifiedData>(null);

	const [dataInternationalDataProviders, setDataInternationalDataProviders] =
		useState<IProvider[] | null>(null);

	// Product State

	const [isLoadingDataProducts, setLoadingDataProducts] =
		useState<boolean>(false);

	const [dataInternationalDataProducts, setDataInternationalDataProducts] =
		useState<Product[] | null>(null);

	const [selectedProduct, setSelectedProduct] = useState<null | Product>(null);

	const isProductPriceRanged = selectedProduct?.priceType === 'range';

	const { createBeneficiary } = useCreateBeneficiary(() => {
		setBeneficiary(null);
	});

	const { coupon, clearCoupon, verifyCoupon, isVerifyingCoupon } =
		useVerifyCoupon();

	const [selectedNetwork, setSelectedNetwork] = React.useState<null | INetwork>(
		null
	);

	useEffect(
		() => {
			if (coupon) {
				dispatch(
					setTransactionBreakdown({
						coupon,
					})
				);
			}
		},
		// eslint-disable-next-line
		[coupon]
	);

	const validationSchema = yup.object().shape({
		phone_number: yup.string().required('Phone number is required'),
		operatorId: yup
			.string()
			.notOneOf([SELECT_PROVIDER], SELECT_PROVIDER)
			.required('Network provider is required'),
		productId: yup
			.string()
			.notOneOf([SELECT_PACKAGE], SELECT_PACKAGE)
			.required('Network package is required'),
		amount: yup
			.number()
			.positive('Amount cannot be negative')
			.required('Amount is required'),
	});

	const initialValues = {
		product: '',
		productId: SELECT_PACKAGE,
		operatorId: SELECT_PROVIDER,
		phone_number: '',
		amount: '',
		discount_code: '',
	};

	const { isLoading: isPurchasingData, mutate: mutatePurchaseData } =
		useMutation(internationalDataPurchase, {
			onSettled: (data, error) => {
				if (error) {
					const response = handleError({ error });
					if (response?.message) {
						modal({
							title: 'Data Purchase',
							message: response.message,
							hasCloseButton: true,
							type: 'error',
							primaryButtonText: 'Close',
							onClickPrimaryButton: () => modal(null),
						});
					}
				}
				if (data && data.success) {
					queryClient.invalidateQueries([QUERY_KEYS.UserWallet]);
					queryClient.invalidateQueries([QUERY_KEYS.Transactions]);
					queryClient.invalidateQueries([QUERY_KEYS.RecentTransactions]);
					dispatch(setTransactionBreakdown(null));
					setSelectedNetwork(null);

					const payload = data.payload;

					setPhoneVerifiedData(null);
					resetForm();
					modal({
						title: 'Data Purchase',
						message: `${payload?.operator} ${
							payload?.product
						} for ${formatNumberToCurrency(payload.amount)} is successful!`,
						type: 'success',
						hasCloseButton: true,
						primaryButtonText: 'Purchase again',
						secondaryButtonText: 'Close',
						onClickPrimaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
						onClickSecondaryButton: () => {
							setSelectedProduct(null);
							modal(null);
						},
					});
					if (isSaveBeneficiary) {
						createBeneficiary({
							type: BENEFICIARY_TYPE.DATA,
							record: beneficiary,
						});
					}
				}
			},
		});

	const {
		errors,
		values,
		touched,
		handleChange,
		handleSubmit,
		setFieldValue,
		resetForm,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			const data: { [key: string]: any } = {
				productId: Number(values.productId),
				operatorId: Number(values.operatorId),
				product: values.product,
				phone_number: values.phone_number,
			};

			let amount = parseFloat(values.amount) || 0;

			const price = selectedProduct?.price;

			const currency = selectedProduct?.currency?.operator;

			if (selectedProduct) {
				if (isProductPriceRanged) {
					const minAmount = parseFloat(`${price?.min.operator}`);
					const maxAmount = parseFloat(`${price?.max.operator}`);

					if (amount < minAmount) {
						setFieldError(
							'amount',
							`Amount must be more than ${formatNumberToCurrency(
								minAmount,
								currency
							)}`
						);
						return;
					}
					if (amount > maxAmount) {
						setFieldError(
							'amount',
							`Amount must be less than ${formatNumberToCurrency(
								maxAmount,
								currency
							)}`
						);
						return;
					}

					data.amount = selectedProduct.conversion_rate * amount;
				} else {
					data.amount = amount;
				}
			}

			// if (values.discount_code) {
			// 	if (!coupon) {
			// 		return alert({ message: 'Apply discount code', type: 'info' });
			// 	}

			// 	if (coupon && coupon.name !== values.discount_code) {
			// 		return alert({ message: 'Invalid coupon code', type: 'error' });
			// 	}

			// 	data.discount_code = values.discount_code;
			// 	amount = calculateDiscountedAmount({ coupon, amount });
			// }

			// mutatePurchaseAirtime(data);

			if (isSaveBeneficiary) {
				setBeneficiary({
					number: values.phone_number,
					transactionType: TRANSACTION_SERVICE.INTERNATIONAL_DATA_SUBSCRIPTION,
					provider: selectedNetwork,
				});
			} else {
				setBeneficiary(null);
			}

			const callback = () => {
				verifyPin(null);
				mutatePurchaseData(data);
			};
			verifyPin({
				title: 'Data Purchase',
				message: `Verify transaction pin to confirm the purchase of ${formatNumberToCurrency(
					amount,
					currency
				)} worth of Data`,
				callback,
			});

			// if (checkKycLevel()) {
			// }
		},
	});

	const { phone_number, amount, discount_code, operatorId, productId } = values;

	const onChangeCode = (code: string) => {
		clearCoupon();
		setFieldValue('discount_code', code);
	};

	const handleApplyCode = () => {
		if (discount_code) {
			verifyCoupon(discount_code);
		}

		alert({ message: 'Enter discount code', type: 'info' });
	};

	// Verify Phone Number
	const handleVerifyNumber = async (phone: string) => {
		try {
			setVerifyingPhone(true);
			const response = await parseMsisdn({ phone });
			if (response.success && response.payload) {
				if (!response.payload.isValid) {
					alert({ message: 'Invalid phone number', type: 'error' });
					return;
				}
				setPhoneVerifiedData(response.payload);

				const country = response.payload.country.id;

				const providersResponse = await internationalDataProviders({
					country,
				});

				if (providersResponse && providersResponse.success) {
					const providers = providersResponse.payload;
					setDataInternationalDataProviders(providers);
				}

				// const operator = response.payload.operator;

				// if (operator.alt) {
				// 	const values = Object.values(operator.alt);
				// 	setDataInternationalDataProviders(values);
				// }
			}
		} catch (error) {
			const response = handleError({ error });
			if (response?.message) {
				alert({
					message: response.message,
					type: 'error',
				});
			}
		} finally {
			setVerifyingPhone(false);
		}
	};

	// Airtime products
	const getInternationalDataProducts = async (providerId: string) => {
		try {
			setLoadingDataProducts(true);
			const response = await internationalDataProducts(providerId);
			if (response.payload) setDataInternationalDataProducts(response.payload);
		} catch (error) {
			const response = handleError({ error });
			if (response?.message) {
				alert({
					message: response.message,
					type: 'error',
				});
			}
		} finally {
			setLoadingDataProducts(false);
		}
	};

	// Handlee Select Provider
	const handleSelectProvider = async (operatorId: string) => {
		if (dataInternationalDataProviders) {
			const provider = dataInternationalDataProviders.find(
				(provider: IProvider) => provider.id === operatorId
			);
			if (provider) {
				setFieldValue('operatorId', operatorId);
			}
		}
		setFieldValue('productId', SELECT_PACKAGE);
		setFieldValue('amount', '');
		dispatch(
			setTransactionBreakdown({
				serviceCost: 0,
			})
		);
		await getInternationalDataProducts(operatorId); // Call products
	};

	const handleSelectProduct = (productId: string): void => {
		// Clear state
		dispatch(setTransactionBreakdown(null));
		setFieldValue('amount', '');
		setFieldValue('productId', '');
		setFieldValue('product', '');

		if (dataInternationalDataProducts) {
			const foundProduct = dataInternationalDataProducts.find(
				(product) => product.id === productId
			);
			if (foundProduct) {
				setSelectedProduct(foundProduct);
				setFieldValue('productId', foundProduct.id);
				setFieldValue('product', foundProduct.name);

				if (foundProduct.priceType === 'fixed') {
					const amount = foundProduct.price.user;
					setFieldValue('amount', amount);
					dispatch(
						setTransactionBreakdown({
							serviceCost: parseFloat(amount),
							currency: foundProduct.currency.user,
						})
					);
				}
			}
		}
	};

	const handleSelectBeneficiary = (beneficiary: { [key: string]: any }) => {
		const phone = beneficiary.record.phone_number;
		const network = beneficiary.record.network;

		setFieldValue('phone_number', phone);
		setSelectedNetwork(network);
	};

	const handleChangePhone = (value: string) => {
		setFieldValue('phone_number', value);

		//Clear state
		setSelectedProduct(null);
		setPhoneVerifiedData(null);
		setDataInternationalDataProviders(null);
		setFieldValue('productId', '');
		setFieldValue('operatorId', '');
		setFieldValue('product', '');
		setFieldValue('amount', '');
	};

	return (
		<Box style={styles.form as any} component={'form'}>
			<Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '8px',
					}}
				>
					<Typography
						sx={{
							marginBottom: '0px !important',
						}}
						style={styles.label}
						component={'label'}
						variant={'body1'}
					>
						Phone number
					</Typography>
					<SelectBeneficiary
						onSelect={handleSelectBeneficiary}
						type={BENEFICIARY_TYPE.DATA}
					/>
				</Box>
				<PhoneTextField
					country='us'
					inputProps={{
						name: '',
						required: true,
						variant: 'standard',
					}}
					value={phone_number}
					onChange={(value) => handleChangePhone(value)}
					error={Boolean(touched.phone_number && errors.phone_number)}
					helperText={touched.phone_number && errors.phone_number}
				/>
			</Box>
			{phoneVerifiedData && (
				<>
					<CustomSelectWithImage
						labelText={SELECT_PROVIDER}
						placeholder={SELECT_PROVIDER}
						error={touched.operatorId && Boolean(errors.operatorId)}
						helpertext={touched.operatorId && errors.operatorId}
						logo={SOCHITEL_LINKS.OperatorLogo}
						options={
							dataInternationalDataProviders
								? [
										...dataInternationalDataProviders.map((data) => ({
											label: data.name,
											value: data.id,
											imageRef: data.brandId?.toString(),
										})),
								  ]
								: null
						}
						value={operatorId}
						onChange={(value) => handleSelectProvider(value)}
					/>

					<CustomSelect
						disabled={operatorId === SELECT_PROVIDER}
						// labelText={SELECT_PACKAGE}
						labelText={
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								<Typography
									sx={{
										display: 'inline-block',
										fontWeight: '600',
									}}
								>
									{SELECT_PACKAGE}
								</Typography>

								{selectedProduct && selectedProduct?.price?.user && amount && (
									<Typography
										sx={{
											display: ['inline-block', 'inline-block', 'none'],
										}}
									>
										{formatNumberToCurrency(
											parseFloat(selectedProduct?.price?.user).toFixed(2)
										)}
									</Typography>
								)}
							</Box>
						}
						isLoading={isLoadingDataProducts}
						placeholder={SELECT_PACKAGE}
						error={touched.productId && Boolean(errors.productId)}
						helpertext={touched.productId && errors.productId}
						options={
							dataInternationalDataProducts
								? [
										{ label: SELECT_PACKAGE, value: '' },
										...dataInternationalDataProducts.map((data) => ({
											label: data.name,
											value: data.id,
										})),
								  ]
								: [{ label: SELECT_PROVIDER, value: '' }]
						}
						value={productId}
						onChange={(value) => handleSelectProduct(value)}
					/>

					{isProductPriceRanged && (
						<Box>
							<TextInput
								labelText={
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Typography
											sx={{
												display: 'inline-block',
												fontWeight: '600',
											}}
										>
											Amount
										</Typography>
										<Typography
											sx={{
												display: ['inline-block', 'inline-block', 'none'],
											}}
										>
											Amount{' '}
											{formatNumberToCurrency(
												parseFloat(amount) *
													parseFloat(`${selectedProduct?.conversion_rate}`),
												selectedProduct?.currency?.user
											)}
										</Typography>
									</Box>
								}
								fullWidth
								placeholder={'Type in amount'}
								error={touched.amount && Boolean(errors.amount)}
								helperText={touched.amount && errors.amount}
								value={amount}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									const amount = e.target.value;
									setFieldValue('amount', amount);
									dispatch(
										setTransactionBreakdown({
											serviceCost:
												parseFloat(amount) *
												parseFloat(`${selectedProduct?.conversion_rate}`),
											currency: selectedProduct?.currency?.user,
										})
									);
								}}
							/>
						</Box>
					)}

					{/* <Box>
				<Typography style={styles.label} component={'label'} variant={'body1'}>
					Discount code [optional]
				</Typography>
				<TextInput
					disabled={isVerifyingCoupon}
					value={discount_code}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangeCode(e.target.value)
					}
					fullWidth
					placeholder={'Type in discount code here'}
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<Button
									loading={isVerifyingCoupon}
									onClick={handleApplyCode}
									disableRipple
									style={styles.applyBtn}
								>
									Apply
								</Button>
							</InputAdornment>
						),
					}}
				/>
			</Box> */}
					<Box>
						<CheckBox
							onChange={() => setSaveBeneficiary(!isSaveBeneficiary)}
							value={isSaveBeneficiary}
							label={'Save number for later'}
						/>
					</Box>
				</>
			)}

			<Button
				loading={isPurchasingData || isVerifyingPhone}
				onClick={(e: React.FormEvent<HTMLButtonElement>) => {
					e.preventDefault();
					if (phoneVerifiedData) {
						handleSubmit();

						return;
					}

					if (phone_number) {
						handleVerifyNumber(phone_number as string);
					} else {
						alert({
							message: 'Please enter a valid phone number',
							type: 'info',
						});
					}
				}}
				size={'large'}
				style={styles.btn}
			>
				{phoneVerifiedData ? 'Purchase' : 'Continue'}
			</Button>
		</Box>
	);
};

const useStyles = (theme: any) => ({
	title: {
		// fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	applyBtn: {
		color: theme.palette.secondary.main,
		fontWeight: '600',
		fontSize: '12px',
		padding: '0px',
		minWidth: 'unset',
	},
});

export default InternationalDataForm;
